import { Breakpoint } from '@procsea/design-system'

export enum TrackingType {
  FILTER = 'Filter',
  CTA = 'CTA',
}

export enum TrackingPersona {
  BUYER = 'buyer',
  CENTRAL = 'central',
  SELLER = 'seller',
}

export enum TrackingSource {
  CREDIT_NOTE_LIST = 'credit_note_list',
  CUSTOM_CATALOG = 'custom_catalog',
  DIRECT_ORDER = 'direct_order',
  INVOICE_LIST = 'invoice_list',
  INVOICE_TO_GENERATE_LIST = 'invoice_to_generate_list',
  LINE_MANAGEMENT = 'line_management',
  LIVE_MARKET = 'live_market',
  ORDER_LIST = 'order_list',
  PRODUCT_MAPPING_SLIDING_PANEL = 'product_mapping_sliding_panel',
  PURCHASE_ORDER_LIST = 'purchase_order_list',
  SCHEDULER = 'scheduler',
  SELLER_LIVE_CATALOG = 'seller_live_catalog',
  STOCK_MANAGEMENT = 'stock_management',
  UNKNOWN = 'unknown',
}

export interface TrackingConfig {
  breakpoint: Breakpoint
  persona: TrackingPersona
  source: TrackingSource
}

export type UseTrackingArgs = Pick<TrackingConfig, 'source'> &
  Partial<Pick<TrackingConfig, 'persona'>>

export interface UseTrackingResult {
  trackFilter: (payload: TrackFilterArgs) => void
  trackCta: (payload: TrackCtaArgs) => void
}

export type TrackFilterValue =
  | boolean
  | string
  | string[]
  | readonly string[]
  | number
  | number[]
  | readonly number[]
  | null
  | undefined

export interface TrackFilterArgs extends Partial<TrackingConfig> {
  filterName: string
  filterValue: TrackFilterValue
}

export enum CtaAction {
  OPEN_MAPPING_POPOVER = 'open_mapping_popover',
  DISMISS_MAPPING_POPOVER = 'dismiss_mapping_popover',
  OPEN_MAPPING_SLIDING_PANEL = 'open_mapping_sliding_panel',
  MAP_TO_CATALOG_PRODUCT = 'map_to_catalog_product',
  NO_PRODUCT_MATCH_THE_OFFER = 'no_product_match_the_offer',
  CREATE_CUSTOMER_CATEGORY_DISCOUNT_EXCEPTION = 'create_customer_category_discount_exception',
  CREATE_CUSTOMER_DISCOUNT_EXCEPTION = 'create_customer_discount_exception',
  DELETE_CUSTOMER_CATEGORY_DISCOUNT_EXCEPTION = 'delete_customer_category_discount_exception',
  DELETE_CUSTOMER_DISCOUNT_EXCEPTION = 'delete_customer_discount_exception',
  UPDATE_CUSTOMER_CATEGORY_DISCOUNT_EXCEPTION = 'update_customer_category_discount_exception',
  UPDATE_CUSTOMER_DISCOUNT_EXCEPTION = 'update_customer_discount_exception',
}

export interface TrackCtaArgs extends Partial<TrackingConfig> {
  action: CtaAction
  label: string | null
  [key: string]: any
}

export const breakpointMapper: Record<Breakpoint, string> = {
  [Breakpoint.IS_MOBILE]: 'mobile',
  [Breakpoint.IS_TABLET]: 'tablet',
  [Breakpoint.IS_SMALL_DESKTOP]: 'small_desktop',
  [Breakpoint.IS_MEDIUM_DESKTOP]: 'medium_desktop',
  [Breakpoint.IS_LARGE_DESKTOP]: 'large_desktop',
  [Breakpoint.IS_DESKTOP]: 'desktop',
}

export const filterNameMapper: Record<string, string> = {
  q: 'search',
  q_items: 'secondary_search',
}
